import React from 'react';
import { Entry } from '@components/entry';

function App() {
  return (
    <Entry />
  );
}

export default App
